<template>
  <div>
    <!-- HANDELBAY INNOVATIVE -->
    <section id="buyers" class="providers">
			<div class="container">
				<div class="row">
					<div class="col-md-6 col-sm-7 col-md-offset-1 text-left">
						<div class="title" itemscope itemtype="https://handelbay.com/">
							<h2 class="title-1" itemprop="HandelBay seguridad">
								{{ $t('lang.providers.do_you_want_to_reach_more_customers') }}
							</h2>
							<h3 class="title-2" itemprop="HandelBay transparancia">
								{{ $t('lang.providers.and_achieve_higher_sales?') }}
							</h3>
							<span class="handelbay-active"></span>
						</div>
						<div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
							<p itemprop="HandelBay es la solución">
								{{ $t('lang.providers.handelbay_is_a_business_sales_platform') }}
							</p>                    
						</div>
						<br>
						<div class="title-footer">
							<a :href="global.sign_up" class="btn btn-handelbay-success btn-handelbay-black" style="margin-top:15px !important;">
							{{ $t('lang.providers.register_now') }}
							</a>
						</div>
					</div>
					<div class="col-md-5 col-sm-5">
							<div class="computers">
									<img src="../assets/images/landing/handelbay/handelbay_quieres_llegar.png"  alt="Es hora de usar HandelBay en tu día a día">
							</div>
					</div>
				</div>
				<!-- .row -->
			</div>
			<!-- .container -->
    </section>
    <!-- END HANDELBAY INNOVATIVE -->

    <!-- .handelbay-background-curva -->
    <section id="handelbay-background-curva" class="handelbay-background-curva-buyers">
    </section>
    <!-- .handelbay-background-curva -->

    <!-- Ahorros en las compras -->
    <section id="savings_in_purchases" class="savings_in_purchases"> 
			<div class="container">
				<div class="row">
					<div class="col-md-4 col-sm-5 col-md-offset-1 col-xs-8 col-xs-offset-2 col-sm-offset-0">
						<div class="img_man">
							<img src="../assets/images/landing/handelbay/handelbay_business_man.png" alt="Es hora de usar HandelBay en tu día a día">
						</div>
					</div>
					<div class="col-md-6 col-sm-7 text-right col-xs-12">                    
						<div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
							<h2 class="title">
								<b>
									{{ $t('lang.providers.more_customers_and_less_effort') }}
								</b>
							</h2>
							<p class="text" itemprop="HandelBay es la solución">
								{{ $t('lang.providers.can_you_imagine_that_buyers_look_for_you_to_participate_in_their_purchase_processes?') }}
							</p>                        
							<p class="text">
								{{ $t('lang.providers.at_HandelBay_we_link_potential_buyers_so_that_you_make_yourself_known') }}
							</p>                        
							<h3 class="title space-title">
								<b>
									{{ $t('lang.providers.security_in_negotiations') }}
								</b>
							</h3>         
							<p class="text">
								{{ $t('lang.providers.at_HandelBay_you_can_do_business_safely') }}
							</p>
						</div>                    
					</div>
				</div>
			</div>
    </section>
    <!-- End ahorros en las compras -->

    <!-- Red de expansión -->
    <section id="expansion_network" class="provider_network">
			<div class="container">
				<div class="row">
					<div class="col-md-4 col-sm-4 col-md-offset-1 text-left">
						<h2 class="title">
							<b>
								{{ $t('lang.providers.complete_portfolio_online_24/7') }}
							</b>
						</h2>
						<p class="text" itemprop="HandelBay es la solución">
							{{ $t('lang.providers.handelbay_will_have_your_portfolio_online_24/7_before_the_growing_network') }}
						</p>                    
					</div>
					<div class="col-md-2 col-sm-4 col-xs-6 col-xs-offset-3 col-sm-offset-0">
						<div class="brand">
							<img src="../assets/images/landing/handelbay/handelbay_img_center.png" alt="Es hora de usar HandelBay en tu día a día">
						</div>
					</div>
					<div class="col-md-4 col-sm-4 text-right col-xs-12">                    
						<div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
							<h3 class="title">
								<b>
									{{ $t('lang.providers.social_network_and_ratings') }}
								</b>
							</h3>
							<p class="text" itemprop="HandelBay red empresarial">
								{{ $t('lang.providers.handelbay_is_a_business_social_network') }}
							</p>
							<p class="text">
								{{ $t('lang.providers.at_HandelBay_you_receive_ratings_from_your_customers') }}
							</p>
						</div>
					</div>
				</div>
			</div>
    </section>
    <!-- End Red de expansión -->

    <!-- Compara cotizaciones y proveedores -->
    <section id="compare_quotes">
			<div class="container">
				<div class="row">                    
					<div class="col-md-6 col-sm-7 col-md-offset-1">                    
						<div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
							<h2 class="title">
								<b>
									{{ $t('lang.providers.manage_your_clients') }}
								</b>
							</h2>
							<p class="text" itemprop="HandelBay es la solución">
								{{ $t('lang.providers.assign_customers_to_each_person_in_your_business_group') }}
							</p>
							<p class="text space-title">
								{{ $t('lang.providers.at_handelbay_you_receive_more_invitations') }}
							</p>
							<h3 class="title space-title">
								<b>
									{{ $t('lang.providers.successful_invitations') }}
								</b>
							</h3>
							<p class="text">
								{{ $t('lang.providers.what_happens_when_you_sell_nails_in_your_portfolio') }}
								<br>
							</p>                                                   
							<p class="text space-title">
								{{ $t('lang.providers.have_you_also_thought_about_translating_your_portfolio_to_reach_new_markets?') }}
							</p>                          
							<p class="text space-title">
								{{ $t('lang.providers.upload_your_product_and_service_portfolio_just_once') }}
								<br>
								{{ $t('lang.providers.this_is_the_future_of_sales_Do_business_without_trade_borders') }}
								<br>
							</p>                                                               
						</div>                    
					</div>
					<div class="col-md-4 col-sm-5 col-xs-6 col-xs-offset-3 col-sm-offset-0">
						<div class="brand">
							<img src="../assets/images/landing/handelbay/handelbay_money_loan_about_us.png" alt="Es hora de usar HandelBay en tu día a día">
						</div>
					</div>
				</div>
			</div>
    </section>
    <!-- End Compara cotizaciones y proveedores -->

    <!-- .handelbay-background-curva -->
    <section id="handelbay-background-curva-bottom">        
    </section>
    <!-- .handelbay-background-curva -->

    <!-- Listo para innovar -->
    <section id="ready_to_innovate">
			<div class="container">
				<div class="title-footer text-center">
					<h2 class="title" style="margin-top:27px;">
						<b>
							<em>
								{{ $t('lang.providers.are_you_ready_to_innovate_in_your_commercial_area?') }}
							</em>
						</b>
					</h2>
					<router-link to="/tarifas" class="btn btn-handelbay-info" @click.native="scrollUp">
						{{ $t('lang.providers.see_provider_plans') }}
					</router-link>
				</div>
			</div>
    </section>
    <!-- End Listo para innovar -->

    <!-- .handelbay-background-curva -->
    <section id="handelbay-background-curva">
    </section>
    <!-- .handelbay-background-curva -->

    <!-- Optimiza tus compras -->
    <section id="optimize_your_purchases" class="optimize_your_purchases">
			<div class="container">
				<div class="row">
					<div class="col-md-10 col-sm-12 col-md-offset-1 text-left">
						<div class="title" itemscope itemtype="https://handelbay.com/">
							<h2 class="title-1" itemprop="HandelBay seguridad">
								{{ $t('lang.providers.optimize') }}
							</h2>
							<h3 class="title-2" itemprop="HandelBay transparancia">
								{{ $t('lang.providers.your_sales') }}
							</h3>
						</div>
						<div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
							<p itemprop="HandelBay es la solución">
								{{ $t('lang.providers.in_four_large_groups_you_will_be_able_to_understand_all_the_features_that_HandelBay_offers') }}
								<br>
								{{ $t('lang.providers.for_commercial_areas_regardless_of_the_size_of_the_company') }}
							</p>                    
						</div>   
						<div class="img_buyers">
							<img src="../assets/images/landing/handelbay/handelbay_optimiza_ventas.png" alt="Optimiza tus compras">
						</div>
					</div>                
				</div>
				<!-- .row -->
				<div class="title_content text-center">
					<h2 class="title">   
						{{ $t('lang.providers.more_buyers_more_opportunities') }}
						<i class="fa fa-caret-right"></i>
					</h2>
				</div>
				<div class="row">
						<div class="col-md-10 col-sm-12 col-md-offset-1 text-left">
							<div class="title" itemscope itemtype="https://handelbay.com/">
								<h2 class="title-1" itemprop="HandelBay seguridad">
									{{ $t('lang.providers.features_for') }}
								</h2>
								<h3 class="title-2" itemprop="HandelBay transparancia">
									{{ $t('lang.providers.providers') }}
								</h3>
							</div>
								<Functionalities />
								<br><br>
								<div class="title-footer text-center">
									<a :href="global.sign_up" class="btn btn-handelbay-success btn-handelbay-black">
										{{ $t('lang.providers.sign_up_free') }}
									</a>
								</div>
						</div>                
				</div>
				<!-- .row -->
			</div>
			<!-- .container -->
    </section>
    <!-- End Optimiza tus compras -->

    <!-- .handelbay-background-curva -->
    <section id="handelbay-background-curva-bottom">        
    </section>
    <!-- .handelbay-background-curva -->

    <PorqueHandelbay />
    
    <ModalTuDemo />
  </div>
</template>
<script>
import global from '@/components/Global.vue'
import Functionalities from "@/components/FunctionalitiesProvider.vue"
import PorqueHandelbay from "@/components/PorqueHandelbay.vue"
import ModalTuDemo from "@/components/ModalTuDemo.vue"
export default{
	components:{
		Functionalities,
		PorqueHandelbay,
		ModalTuDemo
	},
	data(){
		return{
			global: global
		}
	},
	methods:{
		scrollUp: () => {
			window.scroll({top:0})
		}
	}
}
</script>
